const Utilities = {
    asyncForEach: async (array, callback) => {
            for (let index = 0; index < array.length; index++) {
                        await callback(array[index], index, array);
                                }
                                    },
                                    
                                        writeLog : ( text ) => {
                                        
                                            }
                                                ,
                                                    API_KEY : "AIzaSyDnFEqoGod7TNb09Pu0aU8HmyU470sqy2o"
                                                    
                                                    }
                                                    
                                                    export default Utilities;
                                                    
                                                    
                                                    