import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/firebase-functions";
import utils from "./Utils";
const firebaseConfig = {
  apiKey: "AIzaSyAC85WMVeObiCWaNYD8JvGOp3iSKx9EzpI",
  authDomain: "rally-86ce1.firebaseapp.com",
  databaseURL: "https://rally-86ce1.firebaseio.com",
  projectId: "rally-86ce1",
  storageBucket: "rally-86ce1.appspot.com",
  messagingSenderId: "249887937671",
  appId: "1:249887937671:web:5e1142063657f2744d5422",
  measurementId: "G-X2P0EQG0KL",
};

app.initializeApp(firebaseConfig);

class Firebase {
  constructor() {
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.function = app.functions();
    this.app = app;
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  onLogout = (e) => {
    this.auth.signOut();
  };

  //Users Section
  getUsers = (startAt, itemsPerPage, completion) => {
    let users = [];
    let promises = [];
    console.log("startedAt:", startAt);
    promises.push(
      this.db
        .collection("profiles")
        .orderBy("email")
        .startAfter(startAt)
        .limit(itemsPerPage)
        .get()
        .then((snap) => {
          snap.docs.forEach((doc) => {
            let user = doc.data();
            user.id = doc.id;
            users.push(user);
          });
        })
    );

    Promise.all(promises)
      .then((results) => {
        completion(users);
      })
      .catch((error) => {
        completion(null);
      });
  };
  searchUserByName = (keyword, completion) => {
    console.log("searchUserByName :", keyword);
    let users = [];
    let promises = [];

    const searchParts = keyword.split(" ");
    searchParts.forEach((part) => {
      promises.push(
        this.db
          .collection("profiles")
          .where("namesArray", "array-contains", part.toLowerCase())
          .get()
          .then((snap) => {
            snap.docs.forEach((doc) => {
              let user = doc.data();
              user.id = doc.id;
              users.push(user);
            });
          })
      );
    });

    Promise.all(promises)
      .then((results) => {
        completion(users);
      })
      .catch((error) => {
        completion(null);
      });
  };
  getRequestedOrganization = (startAt, itemsPerPage, completion) => {
    console.log("GetRequestedOrganization...");
    let users = [];
    let promises = [];
    console.log("startedAt:", startAt);
    promises.push(
      this.db
        .collection("profiles")
        .where("requestedOrganization", "==", true)
        .orderBy("email")
        .startAfter(startAt)
        .limit(itemsPerPage)
        .get()
        .then((snap) => {
          snap.docs.forEach((doc) => {
            let user = doc.data();
            user.id = doc.id;
            users.push(user);
          });
        })
    );

    Promise.all(promises)
      .then((results) => {
        completion(users);
      })
      .catch((error) => {
        console.log("error:", error);
        completion(null);
      });
  };
  //Flag Section
  getFlagged = (startAt, itemsPerPage, completion) => {
    let flagged = [];
    let promises = [];
    let lastDoc = null;
    console.log("startedAt:", startAt);
    console.log("GetFlagged() ...");
    promises.push(
      this.db
        .collection("flagged")
        .orderBy("id")
        .get()
        .then(async (snap) => {
          console.log("flagged snap length:", snap.docs.length);
          if (snap.docs.length > 0) {
            lastDoc = snap.docs[snap.docs.length - 1];
            console.log('lastDoc"', lastDoc.data());
            await utils.asyncForEach(snap.docs, async (doc) => {
              let flag = doc.data();
              flag.id = doc.id;
              let flagDocs = await this.db
                .collection("flagged")
                .doc(doc.id)
                .collection("flags")
                .get();
              flag.flagCount = flagDocs.docs.length;
              flagged.push(flag);
            });
          }
        })
    );

    Promise.all(promises)
      .then((results) => {
        completion(flagged, lastDoc);
      })
      .catch((error) => {
        console.log("GetFlagged() error:", error);
        completion(null, null);
      });
  };
  getFlaggedUser = (startAt, itemsPerPage, completion) => {
    let flagged = [];
    let promises = [];
    console.log("startedAt:", startAt);
    console.log("GetFlagged() ...");
    promises.push(
      this.db
        .collection("flagged")
        .where("type", "==", "user")
        .orderBy("id")
        .startAfter(startAt)
        .limit(itemsPerPage)
        .get()
        .then(async (snap) => {
          console.log("snap :", snap);
          await utils.asyncForEach(snap.docs, async (doc) => {
            let flag = doc.data();
            flag.id = doc.id;
            let flagDocs = await this.db
              .collection("flagged")
              .doc(doc.id)
              .collection("flags")
              .get();
            flag.flagCount = flagDocs.docs.length;
            flagged.push(flag);
          });
        })
    );

    Promise.all(promises)
      .then((results) => {
        completion(flagged);
      })
      .catch((error) => {
        console.log("GetFlagged() error:", error);
        completion(null);
      });
  };

  handleUpload = (image) => {
    return new Promise((resolve, reject) => {
      const uploadTask = this.storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error) => {
          // Error function ...
          console.log(error);
          reject(error);
        },
        () => {
          // complete function ...
          this.storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
  };

  addBadge = (image, badgeName, score, id, result) => {
    let info = {
      id: id,
      goal: score,
      name: badgeName,
      image: image,
      score: parseInt(score),
    };
    console.log("info:", info);
    this.db
      .collection("badges")
      .doc()
      .set(info)
      .then((_) => {
        result(true);
      })
      .catch((err) => {
        result(false);
      });
  };

  writeLog = async (text) => {
    console.log("writting log.. text:", text);

    let username = "Undefined";
    if (this.auth.currentUser) {
      let profileDoc = await this.db
        .collection("profiles")
        .doc(this.auth.currentUser.uid)
        .get();
      let profile = profileDoc.data();

      username = profile.name ? profile.name : profile.username;
    }

    let info = {
      timestamp: this.app.firestore.FieldValue.serverTimestamp(),
      uid: this.auth.currentUser ? this.auth.currentUser.uid : "undefined",
      text: username + " " + text,
    };

    console.log("info:", info);
    this.db
      .collection("logs")
      .doc()
      .set(info)
      .then((_) => {
        console.log("WriteLog success: text:", text);
      })
      .catch((err) => {
        console.log("WriteLog err :", err);
      });
  };
  removeBadge = (id, result) => {
    console.log("Removing the badge... id:", id);
    this.db
      .collection("badges")
      .doc(id)
      .delete()
      .then((results) => {
        result(true);
      })
      .catch((error) => {
        result(error);
      });
  };

  getBadges = (completion) => {
    let badges = [];
    let promises = [];

    promises.push(
      this.db
        .collection("badges")
        .get()
        .then((snap) => {
          snap.docs.forEach((doc) => {
            let badge = doc.data();
            badge.id = doc.id;
            badges.push(badge);
          });
        })
    );

    Promise.all(promises)
      .then((results) => {
        completion(badges);
      })
      .catch((error) => {
        completion(null);
      });
  };
  getCategories = (completion) => {
    let categories = [];
    let promises = [];

    promises.push(
      this.db
        .collection("categories")
        .get()
        .then((snap) => {
          snap.docs.forEach((doc) => {
            let category = doc.data();
            category.id = doc.id;
            if (!category.description) category.description = "";
            if (!category.image) category.image = null;
            categories.push(category);
          });
        })
    );

    Promise.all(promises)
      .then((results) => {
        completion(categories);
      })
      .catch((error) => {
        completion(null);
      });
  };
  getURLs = (completion) => {
    let urls = [];
    let promises = [];

    promises.push(
      this.db
        .collection("urls")
        .get()
        .then((snap) => {
          snap.docs.forEach((doc) => {
            let url = doc.data();
            url.id = doc.id;
            urls.push(url);
          });
        })
    );

    Promise.all(promises)
      .then((results) => {
        completion(urls);
      })
      .catch((error) => {
        completion(null);
      });
  };
  getResources = (startAt, itemsPerPage, sort, completion) => {
    let resources = [];
    let promises = [];
    console.log("getResources: sort:", sort, ":startAt:", startAt);
    if (startAt == null) {
      promises.push(
        this.db
          .collection("resources")
          .orderBy("name", sort)
          .limit(itemsPerPage)
          .get()
          .then(async (snap) => {
            await utils.asyncForEach(snap.docs, async (doc) => {
              let resource = doc.data();
              resource.id = doc.id;
              //console.log('category:',resource.category);
              let catDoc = await this.db
                .collection("categories")
                .doc(resource.category)
                .get();
              //console.log('catDoc :', catDoc);
              if (catDoc !== undefined) {
                //  console.log('catDoc is not undefined:', catDoc.data());
                if (catDoc.data() !== undefined) {
                  resource.category_name = catDoc.data().name;
                } else resource.category_name = "Category";
              } else resource.category_name = "Category";

              resources.push(resource);
            });
          })
      );
    } else {
      promises.push(
        this.db
          .collection("resources")
          .orderBy("name", sort)
          .startAfter(startAt)
          .limit(itemsPerPage)
          .get()
          .then(async (snap) => {
            await utils.asyncForEach(snap.docs, async (doc) => {
              let resource = doc.data();
              resource.id = doc.id;
              //console.log('category:',resource.category);
              let catDoc = await this.db
                .collection("categories")
                .doc(resource.category)
                .get();
              //console.log('catDoc :', catDoc);
              if (catDoc !== undefined) {
                //  console.log('catDoc is not undefined:', catDoc.data());
                if (catDoc.data() !== undefined) {
                  resource.category_name = catDoc.data().name;
                } else resource.category_name = "Category";
              } else resource.category_name = "Category";

              resources.push(resource);
            });
          })
      );
    }

    Promise.all(promises)
      .then((results) => {
        completion(resources);
      })
      .catch((error) => {
        console.log("getResources Error:", error);
        completion(resources);
      });
  };
  getResourceByCategory = (category_id, completion) => {
    let resources = [];
    let promises = [];

    promises.push(
      this.db
        .collection("resources")
        .where("category", "==", category_id)
        .get()
        .then(async (snap) => {
          await utils.asyncForEach(snap.docs, async (doc) => {
            let resource = doc.data();
            resource.id = doc.id;
            console.log("category:", resource.category);
            let catDoc = await this.db
              .collection("categories")
              .doc(resource.category)
              .get();
            console.log("catDoc :", catDoc);
            if (catDoc !== undefined) {
              console.log("catDoc is not undefined:", catDoc.data());
              if (catDoc.data() !== undefined) {
                resource.category_name = catDoc.data().name;
              } else resource.category_name = "Category";
            } else resource.category_name = "Category";

            resources.push(resource);
          });
        })
    );

    Promise.all(promises)
      .then((results) => {
        console.log("resources:", resources);
        completion(resources);
      })
      .catch((error) => {
        console.log("getResources Error:", error);
        completion(resources);
      });
  };
  addResource = (category, name, website, description, image, result) => {
    let info = {
      category: category,
      name: name,
      website: website,
      image: image,
      description: description,
    };
    console.log("info:", info);
    this.db
      .collection("resources")
      .add(info)
      .then((docRef) => {
        result(docRef.id);
      })
      .catch((err) => {
        console.log("addResource error:", err);
        result(false);
      });
  };
  addCategory = (name, description, url, result) => {
    let info = {
      name: name,
      alias: name,
      description: description,
      image: url,
      id: new Date().getTime(),
    };
    console.log("info:", info);
    this.db
      .collection("categories")
      .add(info)
      .then((docRef) => {
        result(docRef.id);
      })
      .catch((err) => {
        console.log("addResource error:", err);
        result(false);
      });
  };
  addURL = (name, result) => {
    let info = {
      url: name,
    };
    console.log("info:", info);
    this.db
      .collection("urls")
      .add(info)
      .then((docRef) => {
        result(docRef.id);
      })
      .catch((err) => {
        console.log("addResource error:", err);
        result(false);
      });
  };
  removeCategory = (id, result) => {
    console.log("Removing the category... id:", id);
    this.db
      .collection("categories")
      .doc(id)
      .delete()
      .then((results) => {
        result(true);
      })
      .catch((error) => {
        result(error);
      });
  };
  removeURL = (id, result) => {
    console.log("Removing the category... id:", id);
    this.db
      .collection("urls")
      .doc(id)
      .delete()
      .then((results) => {
        result(true);
      })
      .catch((error) => {
        result(error);
      });
  };
  updateCategory = (id, name, description, image, result) => {
    let info = {
      id: id,
      name: name,
      category: name,
      description: description,
      image: image,
    };
    this.db
      .collection("categories")
      .doc(id)
      .set(info, { merge: true })
      .then((results) => {
        result(true);
      })
      .catch((error) => {
        result(error);
      });
  };
  updateURL = (id, name, result) => {
    let info = { url: name };
    this.db
      .collection("categories")
      .doc(id)
      .set(info, { merge: true })
      .then((results) => {
        result(true);
      })
      .catch((error) => {
        result(error);
      });
  };
  updateBadge = (id, name, score, url, result) => {
    console.log("updateBadge() is called...");
    let info = {
      goal: score,
      image: url,
      name: name,
      id: -1,
      score: parseInt(score),
    };
    this.db
      .collection("badges")
      .doc(id)
      .set(info, { merge: true })
      .then((results) => {
        result(true);
      })
      .catch((error) => {
        result(error);
      });
  };
  updateResource = (id, category, name, website, description, url, result) => {
    let info = {
      category: category,
      name: name,
      website: website,
      image: url,
      description: description,
    };
    console.log("updateResource() is called... info:", info);
    this.db
      .collection("resources")
      .doc(id)
      .set(info, { merge: true })
      .then((results) => {
        result(true);
      })
      .catch((error) => {
        console.log("updateResource() error:", error);
        result(error);
      });
  };
  removeResource = (id, result) => {
    console.log("Removing the resource... id:", id);
    this.db
      .collection("resources")
      .doc(id)
      .delete()
      .then((results) => {
        result(true);
      })
      .catch((error) => {
        result(error);
      });
  };

  checkIfEmailExist = (email, result) => {
    console.log("email:", email);
    this.db
      .collection("whitelist")
      .where("email", "==", email)
      .get()
      .then((snap) => {
        if (snap.docs.length > 0) result(true);
        else result(false);
      })
      .catch((err) => {
        result(false);
      });
  };

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  user = (uid) => this.db.collection("profiles").doc(`${uid}`);
  sendEventInvitation = (data, result) => {
    console.log("sending event invitation.. data:", data);
    const sendInviteNotification = this.function.httpsCallable(
      "sendInviteNotification"
    );
    sendInviteNotification({
      eventID: data.eventID,
      eventTitle: data.eventTitle,
      uid: data.uid,
    })
      .then(function (res) {
        console.log("result:", res);
        result(res);
      })
      .catch(function (error) {
        console.log("error:", error);
        result(error);
      });
  };
}

export default Firebase;
